import Report from "./Report";

export default class ReportRepository {

    reports: Report[] = [];

    private static instance: ReportRepository;
    private constructor() { }

    public static getInstance(): ReportRepository {
        if (!ReportRepository.instance) {
            ReportRepository.instance = new ReportRepository();
        }

        return ReportRepository.instance;
    }

    addReport(report: Report) {
        const foundIndex = this.reports.findIndex(element => element.name == report.name);
        if (foundIndex == -1) { // Pas trouvé
            this.reports.push(report);
        }
        else {
            this.reports[foundIndex] = report;
        }
    }

    deleteReport(report: Report) {
        const foundIndex = this.reports.findIndex(element => element.name == report.name);
        if (foundIndex != -1) { // Pas trouvé
            const newReports: Report[] = [];
            for(let i=0; i<this.reports.length; i++) {
                if(i != foundIndex) {
                    newReports.push(this.reports[i]);
                }
            }
            this.reports = newReports;
        }
    }

    saveReports() {
        const parsedReports = JSON.stringify(this.reports);
        localStorage.setItem("savedReports", parsedReports);
    }

    getReports(): Report[] {
        const savedReports = localStorage.getItem("savedReports");
        if (savedReports) {
            try {
                this.reports = JSON.parse(savedReports);
            } catch (e) {
                localStorage.removeItem("savedReports");
            }
        }

        return this.reports;
    }
}