<template>
  <div class="layout-footer">
    <img alt="Logo" src="images/kronopsy.png" height="20" class="mr-2" />
    -
    <span class="font-medium ml-2">Version 0.1</span>
  </div>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>