import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('./components/Home.vue'),
                children: [
                    {
                        path: '',
                        name: 'start',
                        component: () => import('./components/StartForm.vue')
                    },
                    {
                        path: '/tests',
                        name: 'tests',
                        component: () => import('./components/TestsForm.vue')
                    },
                    {
                        path: '/wisc',
                        name: 'wisc',
                        component: () => import('./components/WiscForm.vue')
                    },
                    {
                        path: '/bonhomme',
                        name: 'bonhomme',
                        component: () => import('./components/BonhommeForm.vue')
                    },
                    {
                        path: '/raven',
                        name: 'raven',
                        component: () => import('./components/RavenForm.vue')
                    }
                ]
            },
            {
                path: '/report/new',
                name: 'newReport',
                component: () => import('./components/Report.vue')
            },
            {
                path: '/report/:id',
                name: 'viewReport',
                component: () => import('./components/Report.vue'),
                props: true
            }
        ]
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
